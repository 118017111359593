import { createApp } from 'vue';
import './style.css';
import App from './App.vue';
import router, { setupRouter } from './router';

const app = createApp(App);

// 配置路由
setupRouter(app);

// 等待路由准备好，然后再挂载应用
router.isReady().then(() => {
  app.mount('#app');
});
